/* eslint-disable no-unused-vars */
import { React, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useReducer } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { getError } from "../LoadingError/Utils";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loading: true };
    case "FETCHED":
      return { ...state, dataUser: action.payload, loading: false };
    case "FETCH_FAILED":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const MobileFooter = ({ userInfo, showSignInModalChat }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isSearch = location.pathname === "/search";
  const [isLoadingImg, setIsLoadingImg] = useState(true);

  const StyledFab = styled(Fab)({
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
    boxShadow: "0px 2px 2px 2px gainsboro",
    backgroundColor: isSearch ? "#F95C3D" : "white",
  });

  const [{ error, dataUser }, dispatch] = useReducer(reducer, {
    dataUser: [],
    loading: true,
    error: "",
  });

  const signoutHandler = () => {
    localStorage.removeItem("userInfo");
  };

  function SetProfilePicture() {
    const isNull = dataUser.picture;
    if (isNull === "null" || isNull === null) {
      return <DefaultPP />;
    } else {
      return <ProfilePicture />;
    }
  }

  function DefaultPP() {
    return (
      <img
        className={`w-[30px] h-[30px] rounded-full mb-1 ${
          location.pathname === `/${dataUser.username}`
            ? "border-2 border-[#F95C3D]"
            : ""
        }`}
        src="../images/pp/defaultpp.png"
        alt="defaultprofilepicture"
      />
    );
  }
  useEffect(() => {
    const img = new Image();
    img.src = dataUser.picture;
    img.onload = () => setIsLoadingImg(false);
  }, [dataUser.picture]);

  function ProfilePicture() {
    return (
      <>
        {isLoadingImg ? (
          <div
            className={`w-[30px] h-[30px] rounded-full mb-1 ${
              isLoadingImg ? "animate-pulse bg-slate-400" : ""
            }`}
          ></div>
        ) : (
          <img
            className={`w-[30px] h-[30px] rounded-full mb-1 ${
              location.pathname === `/${dataUser.username}`
                ? "border-2 border-[#F95C3D]"
                : ""
            }`}
            src={dataUser.picture}
            alt="profilepicture"
          />
        )}
      </>
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      if (userInfo !== null) {
        dispatch({
          type: "FETCHING",
        });

        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/me`,
            {
              headers: { Authorization: `Bearer ${userInfo.data.token}` },
            }
          );

          dispatch({
            type: "FETCHED",
            payload: data.data,
          });
        } catch (error) {
          dispatch({
            type: "FETCH_FAILED",
            payload: error.message,
          });

          if (error.response && error.response.status === 401) {
            // Assuming you have a signoutHandler function
            signoutHandler();
            navigate("/signin");
          } else {
            toast.error(getError(error));
          }
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <>
      <div className="lg:hidden app-bar-bottom bg-white text-gray-500 z-[1002]">
        <ul className="grid grid-cols-5 gap-4 border-t border-[#D1D1D1]">
          <li
            className={`${
              location.pathname === "/"
                ? "col-span-1 pt-2 h-16 border-t-4 border-[#F95C3D] flex justify-center"
                : "col-span-1 pt-2 h-16 flex justify-center"
            }`}
          >
            <a
              href="/"
              className={`block text-center text-[12px] ${
                location.pathname === "/" ? "text-[#F95C3D]" : ""
              }`}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mx-auto mb-1 scale-75"
              >
                <path
                  d="M10.3333 29V15H19.6667V29M1 10.8L15 1L29 10.8V26.2C29 26.9426 28.6722 27.6548 28.0888 28.1799C27.5053 28.705 26.714 29 25.8889 29H4.11111C3.28599 29 2.49467 28.705 1.91122 28.1799C1.32778 27.6548 1 26.9426 1 26.2V10.8Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Home
            </a>
          </li>
          <li
            className={`${
              location.pathname === "/add_catalogue"
                ? "col-span-1 pt-2 h-16 border-t-4 border-[#F95C3D] flex justify-center"
                : "col-span-1 pt-2 h-16 flex justify-center text-[12px]"
            }`}
          >
            {!userInfo ? (
              <button
                onClick={showSignInModalChat}
                className="block text-center text-[12px]"
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-auto mb-1 scale-75"
                >
                  <path
                    d="M14.9375 29.4375C6.94179 29.4375 0.4375 22.9332 0.4375 14.9375C0.4375 6.94179 6.94179 0.4375 14.9375 0.4375C22.9332 0.4375 29.4375 6.94179 29.4375 14.9375C29.4375 22.9332 22.9332 29.4375 14.9375 29.4375ZM14.9375 2.50893C8.08107 2.50893 2.50893 8.08107 2.50893 14.9375C2.50893 21.7939 8.08107 27.3661 14.9375 27.3661C21.7939 27.3661 27.3661 21.7939 27.3661 14.9375C27.3661 8.08107 21.7939 2.50893 14.9375 2.50893Z"
                    fill="currentColor"
                  />
                  <path
                    d="M14.9371 22.1875C14.3571 22.1875 13.9014 21.7318 13.9014 21.1518V8.72321C13.9014 8.14321 14.3571 7.6875 14.9371 7.6875C15.5171 7.6875 15.9728 8.14321 15.9728 8.72321V21.1518C15.9728 21.7318 15.5171 22.1875 14.9371 22.1875Z"
                    fill="currentColor"
                  />
                  <path
                    d="M21.1518 15.9733H8.72321C8.14321 15.9733 7.6875 15.5176 7.6875 14.9376C7.6875 14.3576 8.14321 13.9019 8.72321 13.9019H21.1518C21.7318 13.9019 22.1875 14.3576 22.1875 14.9376C22.1875 15.5176 21.7318 15.9733 21.1518 15.9733Z"
                    fill="currentColor"
                  />
                </svg>
                Add Catalogue
              </button>
            ) : (
              <Link
                to="/add_catalogue"
                className={`block text-center text-[12px] ${
                  location.pathname === "/add_catalogue"
                    ? "text-[#F95C3D] text-[12px]"
                    : ""
                }`}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-auto mb-1 scale-75"
                >
                  <path
                    d="M14.9375 29.4375C6.94179 29.4375 0.4375 22.9332 0.4375 14.9375C0.4375 6.94179 6.94179 0.4375 14.9375 0.4375C22.9332 0.4375 29.4375 6.94179 29.4375 14.9375C29.4375 22.9332 22.9332 29.4375 14.9375 29.4375ZM14.9375 2.50893C8.08107 2.50893 2.50893 8.08107 2.50893 14.9375C2.50893 21.7939 8.08107 27.3661 14.9375 27.3661C21.7939 27.3661 27.3661 21.7939 27.3661 14.9375C27.3661 8.08107 21.7939 2.50893 14.9375 2.50893Z"
                    fill="currentColor"
                  />
                  <path
                    d="M14.9371 22.1875C14.3571 22.1875 13.9014 21.7318 13.9014 21.1518V8.72321C13.9014 8.14321 14.3571 7.6875 14.9371 7.6875C15.5171 7.6875 15.9728 8.14321 15.9728 8.72321V21.1518C15.9728 21.7318 15.5171 22.1875 14.9371 22.1875Z"
                    fill="currentColor"
                  />
                  <path
                    d="M21.1518 15.9733H8.72321C8.14321 15.9733 7.6875 15.5176 7.6875 14.9376C7.6875 14.3576 8.14321 13.9019 8.72321 13.9019H21.1518C21.7318 13.9019 22.1875 14.3576 22.1875 14.9376C22.1875 15.5176 21.7318 15.9733 21.1518 15.9733Z"
                    fill="currentColor"
                  />
                </svg>
                Add Catalogue
              </Link>
            )}

            {/* {!userInfo ? (
              <button
                onClick={showSignInModalChat}
                className="block text-center text-[12px]"
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-auto mb-1 scale-75"
                >
                  <path
                    d="M14.9375 29.4375C6.94179 29.4375 0.4375 22.9332 0.4375 14.9375C0.4375 6.94179 6.94179 0.4375 14.9375 0.4375C22.9332 0.4375 29.4375 6.94179 29.4375 14.9375C29.4375 22.9332 22.9332 29.4375 14.9375 29.4375ZM14.9375 2.50893C8.08107 2.50893 2.50893 8.08107 2.50893 14.9375C2.50893 21.7939 8.08107 27.3661 14.9375 27.3661C21.7939 27.3661 27.3661 21.7939 27.3661 14.9375C27.3661 8.08107 21.7939 2.50893 14.9375 2.50893Z"
                    fill="currentColor"
                  />
                  <path
                    d="M14.9371 22.1875C14.3571 22.1875 13.9014 21.7318 13.9014 21.1518V8.72321C13.9014 8.14321 14.3571 7.6875 14.9371 7.6875C15.5171 7.6875 15.9728 8.14321 15.9728 8.72321V21.1518C15.9728 21.7318 15.5171 22.1875 14.9371 22.1875Z"
                    fill="currentColor"
                  />
                  <path
                    d="M21.1518 15.9733H8.72321C8.14321 15.9733 7.6875 15.5176 7.6875 14.9376C7.6875 14.3576 8.14321 13.9019 8.72321 13.9019H21.1518C21.7318 13.9019 22.1875 14.3576 22.1875 14.9376C22.1875 15.5176 21.7318 15.9733 21.1518 15.9733Z"
                    fill="currentColor"
                  />
                </svg>
                Upload
              </button>
            ) : (
              <Link
                to="/add_post"
                className={`block text-center text-[12px] ${
                  location.pathname === "/add_post"
                    ? "text-[#F95C3D] text-[12px]"
                    : ""
                }`}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-auto mb-1 scale-75"
                >
                  <path
                    d="M14.9375 29.4375C6.94179 29.4375 0.4375 22.9332 0.4375 14.9375C0.4375 6.94179 6.94179 0.4375 14.9375 0.4375C22.9332 0.4375 29.4375 6.94179 29.4375 14.9375C29.4375 22.9332 22.9332 29.4375 14.9375 29.4375ZM14.9375 2.50893C8.08107 2.50893 2.50893 8.08107 2.50893 14.9375C2.50893 21.7939 8.08107 27.3661 14.9375 27.3661C21.7939 27.3661 27.3661 21.7939 27.3661 14.9375C27.3661 8.08107 21.7939 2.50893 14.9375 2.50893Z"
                    fill="currentColor"
                  />
                  <path
                    d="M14.9371 22.1875C14.3571 22.1875 13.9014 21.7318 13.9014 21.1518V8.72321C13.9014 8.14321 14.3571 7.6875 14.9371 7.6875C15.5171 7.6875 15.9728 8.14321 15.9728 8.72321V21.1518C15.9728 21.7318 15.5171 22.1875 14.9371 22.1875Z"
                    fill="currentColor"
                  />
                  <path
                    d="M21.1518 15.9733H8.72321C8.14321 15.9733 7.6875 15.5176 7.6875 14.9376C7.6875 14.3576 8.14321 13.9019 8.72321 13.9019H21.1518C21.7318 13.9019 22.1875 14.3576 22.1875 14.9376C22.1875 15.5176 21.7318 15.9733 21.1518 15.9733Z"
                    fill="currentColor"
                  />
                </svg>
                Upload
              </Link>
            )} */}
          </li>
          <li
            className={`${
              location.pathname === "/search"
                ? "col-span-1 pt-10 h-16 text-[#F95C3D] flex justify-center text-[12px]"
                : "col-span-1 pt-10 h-16 flex justify-center text-[12px]"
            }`}
          >
            Search
          </li>
          <li
            className={`${
              location.pathname === "/all_chat"
                ? "col-span-1 pt-2 h-16 border-t-4 border-[#F95C3D] flex justify-center text-[12px]"
                : "col-span-1 pt-2 h-16 flex justify-center text-[12px]"
            }`}
          >
            {!userInfo ? (
              <button
                onClick={showSignInModalChat}
                className="block text-center"
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-auto mb-1 scale-75"
                >
                  <path
                    d="M4.49993 24.284V30L6.77239 28.6203L13.9153 24.284H20.9997C22.6541 24.284 23.9996 22.9226 23.9996 21.2485V9.10634C23.9996 7.43224 22.6541 6.0708 20.9997 6.0708H2.99995C1.34548 6.0708 0 7.43224 0 9.10634V21.2485C0 22.9226 1.34548 24.284 2.99995 24.284H4.49993ZM2.99995 9.10634H20.9997V21.2485H13.0843L7.49988 24.6392V21.2485H2.99995V9.10634Z"
                    fill="currentColor"
                  />
                  <path
                    d="M27.0006 0H9.00093C7.34646 0 6.00098 1.36144 6.00098 3.03554H24.0007C25.6552 3.03554 27.0006 4.39698 27.0006 6.07108V18.2132C28.6551 18.2132 30.0006 16.8518 30.0006 15.1777V3.03554C30.0006 1.36144 28.6551 0 27.0006 0Z"
                    fill="currentColor"
                  />
                </svg>
                Chat
              </button>
            ) : (
              <Link
                to="/all_chat"
                className={`block text-center ${
                  location.pathname === "/all_chat"
                    ? "text-[#E9500E] text-[12px]"
                    : ""
                }`}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-auto mb-1 scale-75"
                >
                  <path
                    d="M4.49993 24.284V30L6.77239 28.6203L13.9153 24.284H20.9997C22.6541 24.284 23.9996 22.9226 23.9996 21.2485V9.10634C23.9996 7.43224 22.6541 6.0708 20.9997 6.0708H2.99995C1.34548 6.0708 0 7.43224 0 9.10634V21.2485C0 22.9226 1.34548 24.284 2.99995 24.284H4.49993ZM2.99995 9.10634H20.9997V21.2485H13.0843L7.49988 24.6392V21.2485H2.99995V9.10634Z"
                    fill="currentColor"
                  />
                  <path
                    d="M27.0006 0H9.00093C7.34646 0 6.00098 1.36144 6.00098 3.03554H24.0007C25.6552 3.03554 27.0006 4.39698 27.0006 6.07108V18.2132C28.6551 18.2132 30.0006 16.8518 30.0006 15.1777V3.03554C30.0006 1.36144 28.6551 0 27.0006 0Z"
                    fill="currentColor"
                  />
                </svg>
                Chat
              </Link>
            )}
          </li>
          <li
            className={`${
              location.pathname === `/${dataUser.username}`
                ? "col-span-1 pt-2 h-16 border-t-4 border-[#F95C3D] flex justify-center text-[12px]"
                : "col-span-1 pt-2 h-16 flex justify-center text-[12px]"
            }`}
          >
            {!userInfo ? (
              <Link
                to="/signin"
                className={`block text-center items-center space-x-3 rounded-md ${
                  location.pathname === "/signin"
                    ? "bg-[#F95C3D] text-[#F95C3D]"
                    : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mb-1 scale-75"
                  width="30"
                  height="30"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Sign In
              </Link>
            ) : (
              <Link
                to={`/${dataUser.username}`}
                className={`flex flex-col justify-center items-center text-[12px] ${
                  location.pathname === `/${dataUser.username}`
                    ? "text-[#E9500E] text-[12px]"
                    : ""
                }`}
              >
                <SetProfilePicture />
                <span>Profile</span>
              </Link>
            )}
          </li>
        </ul>
      </div>
      <div className="lg:hidden fixed inset-x-0 bottom-16 z-[1003] ">
        <Link
          to="/search"
          className={`block text-center ${
            location.pathname === "/search" ? "text-[#E9500E]" : ""
          }`}
        >
          <StyledFab aria-label="Search">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto mb-1"
            >
              <path
                d="M29 29L22.2333 22.2333M25.8889 13.4444C25.8889 20.3173 20.3173 25.8889 13.4444 25.8889C6.57157 25.8889 1 20.3173 1 13.4444C1 6.57157 6.57157 1 13.4444 1C20.3173 1 25.8889 6.57157 25.8889 13.4444Z"
                stroke={`${
                  location.pathname === "/search" ? "white" : "rgb(107 114 128)"
                }`}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </StyledFab>
        </Link>
      </div>
    </>
  );
};

export default MobileFooter;
